import { DocumentType, UserType } from '@innedit/innedit-type';
import compact from 'lodash/compact';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Admin/props';
import IconUserCircle from '~/icons/UserCircle';

import ListItem from './index';

const ListItemUser: FC<ListItemProps<UserType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<UserType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const label = compact(
    model.labelFields.map(field => (doc[field] ? doc[field] : undefined)),
  ).join(' - ');

  return (
    <ListItem
      displayActionId={false}
      doc={doc}
      icon={doc.uid && <IconUserCircle />}
      index={index}
      label={label}
      onClick={onClick}
      tags={compact([
        doc.stripeAccountId && {
          label: 'Compte connecté',
        },
        doc.stripeAccountVerified && {
          label: 'Vérifié',
        },
      ])}
    />
  );
};

export default ListItemUser;
